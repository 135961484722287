/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash'
import moment from 'moment/min/moment-with-locales'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useSystemSettings,
  useUser,
} from 'react-omnitech-api'
import { useAlert, useShare } from '../../../hook'
import { withAuthenticableNavigationBlock } from '../../../ui'
import AccountReferralView from './account-referral-view'

function AccountReferralController() {
  // prepare hook
  const alert = useAlert()
  const { share } = useShare()
  const { user } = useUser()
  const { t } = useTranslation()
  const { getSystemSetting } = useSystemSettings()
  const appUrl = getSystemSetting('frontend.app_url')

  // state

  // local variable
  const seoTitle = t('screens.accountReferral.seo.title')

  const isBrowserSupportCopyText = _.isFunction(_.get(navigator, 'clipboard.writeText'))

  const code = useMemo(() => _.get(user, 'membershipCode'), [user])
  const spendingsData = useMemo(() => _.get(user, 'affiliationData.affiliatedUsersSpending', []), [user])

  const getApendingsContext = (n) => {
    switch (n) {
      case 0:
        return 'zero'
      case 1:
        return 'one'
      default:
        return 'other'
    }
  }

  const spendings = useMemo(() => (
    _.sortBy(
      _.map(spendingsData, ({ month, year, totalSpending }) => {
        const dataMonth = moment().year(year).month(month - 1).date(moment().date())
        const monthDiffs = Math.round(moment.duration(moment().diff(dataMonth)).asMonths())
        const formatedDate = dataMonth.format(t('screens.accountReferral.spendings.itemDateFormat'))
        const title = t('screens.accountReferral.spendings.itemTitle', {
          date: formatedDate,
          context: getApendingsContext(monthDiffs),
        })
        const description = t('screens.accountReferral.spendings.itemDescription', {
          date: moment().format('L'),
          context: getApendingsContext(monthDiffs),
        })
        return {
          title,
          description,
          amount: totalSpending,
          monthDiffs,
        }
      }),
      'monthDiffs',
    )
  ), [spendingsData])

  const onCopyText = useCallback(() => {
    if (!isBrowserSupportCopyText) return
    navigator.clipboard.writeText(code)
    alert.show(t('screens.accountReferral.shareCode.copySuccessMessage'), { state: 'success' })
    _.delay(() => alert.remove(), 2000)
  }, [code, isBrowserSupportCopyText])

  const onShare = useCallback(() => {
    share({
      modalDialogMessage: t('screens.accountReferral.modalDialog.share.message'),
      modalDialogTitle: t('screens.accountReferral.modalDialog.share.title'),
      message: t('screens.accountReferral.shareCode.shareMessage', { code }),
      title: t('screens.accountReferral.shareCode.shareMessage', { code }),
      url: appUrl,
      imageUrl: '',
    })
  }, [code])

  const viewProps = {
    seoTitle,
    code,
    spendings,
    isBrowserSupportCopyText,
    onCopyText,
    onShare,
  }

  return (
    <AccountReferralView {...viewProps} />
  )
}

export default withAuthenticableNavigationBlock(AccountReferralController)
