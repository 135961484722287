import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  constinerFluid: {
    padding: [[26, 0, 30]],
    width: '100%',
  },
  container: {
    textAlign: 'center',
    padding: [[0, 20]],
    margin: [[0, 'auto']],
    maxWidth: `${theme.mediaQueries.mobile}px`,
  },
  shareCode: {
    marginBottom: '2.4rem',
    borderBottom: `0.1rem solid ${theme.colors.gray500}`,
  },
  headerTitle: {
    fontSize: '2rem',
    lineHeight: 1.25,
  },
  headerDescription: {
    fontSize: '1.6rem',
    lineHeight: 1.25,
    color: theme.colors.gray500,
  },
  referralCode: {
    maxWidth: '22rem',
    width: '100%',
    backgroundColor: theme.colors.light,
    margin: [['3rem', 'auto']],
    padding: '1rem',
    cursor: 'default',
    border: 'none',
    borderRadius: '0.3rem',
  },
  referralCodeClickable: {
    cursor: 'pointer',
  },
  referralCodeText: {
    display: 'block',
    fontSize: '1.6rem',
    lineHeight: 1.25,
    margin: [['0.5rem', 0]],
  },
  referralCodeTextCopy: {
    fontSize: '1.2rem',
    lineHeight: 1.25,
    color: theme.colors.gray500,
  },
  buttons: {
    margin: [[0, 0, '3rem']],
  },
  shareButton: {
    padding: [['1rem', '2rem']],
    backgroundColor: theme.colors.secondary,
    borderColor: theme.colors.secondary,
    borderRadius: '5rem',
    fontSize: '1.4rem',
    lineHeight: 1.25,
    color: '#ffffff',
  },
  spendings: {

  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    borderBottom: `0.1rem solid ${theme.colors.gray500}`,
    padding: [['2rem', 0]],
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  itemLeft: {
    textAlign: 'left',
  },
  itemRight: {
    textAlign: 'right',
  },
  itemText: {
    lineHeight: 1.25,
  },
  itemTextTitle: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    marginBottom: '1.5rem',
  },
  itemTextDesc: {
    fontSize: '1.2rem',
    color: theme.colors.gray500,
  },
  itemTextAmount: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
  },

}))

export default useStyles
